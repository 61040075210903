import { FC, useState } from 'react';

// * Components
import { Button } from '../../Button';

// * Props
import { ChannelsProps } from './Channels.interface';

// * Styles
import styles from "./Channels.module.css";
import ConnectedChannel from './ConnectedChannel';
import { ButtonGroup } from '../../ButtonGroup';
import PopupChannelPagination from './PopupChannelPagination/PopupChannelPagination';
import { useChannelStatuses } from '../../../hooks/useChannelStatus';
import { useScreenWidth } from '../../../hooks/useScreenWidth';
import { Channel } from '../../../features/api/apiSlice';

const hasActiveChannels = (channels?: Channel[]) => {
  return channels?.some(channel => channel.enabled);
};

const Channels: FC<ChannelsProps> = ({
  channels,
  onToggleEnabled,
  onEdit,
  onDelete,
  onAddNewChannel
}) => {
  const { isMobile } = useScreenWidth();
  const { channelStatuses } = useChannelStatuses(channels);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(prevState => !prevState);
  };

  const showEmptyState = !hasActiveChannels(channels);

  return (
    <div className={styles.channels}>
      {isMobile && (
        <div className={styles.channels__header}>
          <ButtonGroup className={styles.channels__controls}>
            <Button
              className={styles.channels__management}
              kind="primary"
              onClick={togglePopup}
            >
              Управление каналами
            </Button>
          </ButtonGroup>
          <PopupChannelPagination
            isOpen={isPopupOpen}
            onClose={togglePopup}
            onAddNewChannel={onAddNewChannel}
            onDelete={onDelete}
            onEdit={onEdit}
            onToggleEnabled={onToggleEnabled}
            channelStatuses={channelStatuses}
          />
        </div>
      )}

      <div className={styles.channels__content}>
        {showEmptyState ? (
          <div className={styles.channels__empty}>
            <p className={styles.channels__empty_text}>
              Здесь будут ваши активные каналы
            </p>
          </div>
        ) : (
          <ul className={styles.channels__list}>
            {channels?.map((channel) => (
              <ConnectedChannel
                key={channel.id}
                channel={channel}
                status={channelStatuses[channel.id]}
                onDelete={onDelete}
                onEdit={onEdit}
                onToggleEnabled={onToggleEnabled}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Channels;