import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

// * Styles
import styles from './PopupChannelPagination.module.css';

// * Components
import { Popup } from '../../../Popup';
import { Button } from '../../../Button';
import PopupStructure from '../../../PopupStructure/PopupStructure';
import ConnectedChannel from '../ConnectedChannel/ConnectedChannel';
import { Pagination } from '../../../Pagination';
import Icon from '../../../Icon';
import MultipleDeleteChannelPopup from '../../../MultipleDeleteChannelPopup/MultipleDeleteChannelPopup';
import DeleteChannelPopup from '../../../DeleteChannelPopup/DeleteChannelPopup';
import TooltipButton from '../../../TooltipButton';
import { ButtonToggleOnOff } from '../../../ButtonToggleOnOf/ButtonToggleOnOff';

// * Types
import { PopupChannelPaginationProps } from './PopupChannelPagination.interface';
import {
  Channel,
  useDeleteChannelMutation,
  useFetchRestreamTemplatesWithBroadcastQuery,
  useToggleChannelsMutation
} from '../../../../features/api/apiSlice';

// * Hooks
import { useChannelStatuses } from '../../../../hooks/useChannelStatus';
import { useScreenWidth } from '../../../../hooks/useScreenWidth';

const CHANNEL_LIST_ITEMS_PER_PAGE = 12;
const ChannelCategoriesTooltipText =`
  В разделе <b>Интегрированные</b> будут отображаться платформы, добавленные с помощью авторизации на них, а именно Twitch, Trovo, ВК, GoodGame, Youtube.<br><br>
  В разделе <b>Запланированные</b> будут отображаться каналы YouTube и ВКонтакте с заранее созданными трансляциями.<br><br>
  В разделе <b>Активные</b> будут отображаться включенные каналы.
`;
const CHANNEL_FILTERS = [
  { type: 'all', label: 'Все' },
  { type: 'api_authorized', label: 'Интегрированные' },
  { type: 'scheduled', label: 'Запланированные' },
  { type: 'active', label: 'Активные' }
] as const;

type ChannelFilterType = typeof CHANNEL_FILTERS[number]['type'];

const PopupChannelPagination: FC<PopupChannelPaginationProps> = ({
  isOpen,
  onClose,
  onAddNewChannel,
  onEdit,
  onToggleEnabled,
}) => {
  const { isMobile } = useScreenWidth();
  const [activeFilterType, setActiveFilterType] = useState<ChannelFilterType>('all');
  const [selectedChannelIds, setSelectedChannelIds] = useState<number[]>([]);
  const [isDeleteChannelPopup, setIsDeleteChannelPopup] = useState(false);
  const [channelToDelete, setChannelToDelete] = useState<Channel | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const params = useParams();
  const broadcastId = Number(params['id']);

  useEffect(() => {
    if (!isOpen) {
      setSelectedChannelIds([]);
    }
    setCurrentPage(1);
  }, [isOpen]);

  const [isToggleChannels] = useToggleChannelsMutation();
  const [deleteChannel] = useDeleteChannelMutation();
  const { data: channelsPagination, refetch } = useFetchRestreamTemplatesWithBroadcastQuery({
    broadcastId,
    group: activeFilterType,
    page: currentPage,
  });

  const { channelStatuses } = useChannelStatuses(channelsPagination?.restream_templates);

  useEffect(() => {
    if (!channelsPagination) return;

    const totalPages = Math.ceil(channelsPagination.total / CHANNEL_LIST_ITEMS_PER_PAGE);
    if (currentPage <= totalPages || totalPages === 0) return;

    setCurrentPage(totalPages);
  }, [channelsPagination]);

  const handleToggle = (target: "on" | "off") => {
    isToggleChannels({
      broadcastId,
      target,
      group: activeFilterType,
      page: currentPage
    });
  };

  const handleCheckboxChange = (channelId: number) => {
    setSelectedChannelIds(prevSelected =>
      prevSelected.includes(channelId)
        ? prevSelected.filter(id => id !== channelId)
        : [...prevSelected, channelId]
    );
  };

  const handleCancelSelection = () => {
    setSelectedChannelIds([]);
  };

  const handleCloseDeletePopup = () => {
    setIsDeleteChannelPopup(false);
  };

  const handleSuccessDelete = () => {
    setIsDeleteChannelPopup(false);
    setSelectedChannelIds([]);
    refetch();
  };

  const handleDeleteChannel = async (id: number) => {
    try {
      await deleteChannel(id).unwrap();
      refetch();
    } catch (error) {
      console.error('Failed to delete channel:', error);
    }
  };

  const handleFilterChange = (filterType: ChannelFilterType) => {
    setActiveFilterType(filterType);
    setCurrentPage(1);
    setSelectedChannelIds([]);
  };

  const handlePageChange = (newPage: number) => {
    if (!channelsPagination) return;

    const totalPages = Math.ceil(channelsPagination.total / CHANNEL_LIST_ITEMS_PER_PAGE);
    if (newPage > totalPages) return;

    setCurrentPage(newPage);
  };

  const renderChannelColumns = (channels: Channel[]) => {
    const columns = [channels.slice(0, 6), channels.slice(6, 12)];

    return columns.map((column, index) => (
      <div key={index} className={styles['channel-settings__column']}>
        {column.map(channel => (
          <ConnectedChannel
            key={channel.id}
            channel={channel}
            status={channelStatuses[channel.id]}
            onEdit={onEdit}
            onToggleEnabled={onToggleEnabled}
            isChecked={selectedChannelIds.includes(channel.id)}
            onCheckboxChange={() => handleCheckboxChange(channel.id)}
            onDelete={() => setChannelToDelete(channel)}
            showCheckbox
          />
        ))}
      </div>
    ));
  };

  const renderEmptyState = () => (
    <div className={styles['empty-state']}>
      <p className={styles['empty-state__text']}>У вас ещё нет добавленных каналов</p>
      <Button kind="primary" onClick={onAddNewChannel}>
        <span className={styles['button__text']}>+ Добавить канал</span>
      </Button>
    </div>
  );

  const renderHeader = () => (
    <>
      <h2 className={styles['popup__title']}>Ваши каналы</h2>
      <div className={styles['popup__header']}>
        <Button
          kind="primary"
          onClick={onAddNewChannel}
          className={styles['add-button']}
        >
          <span className={styles['add-button__text']}>+ Добавить канал</span>
        </Button>
        <ButtonToggleOnOff
          onToggleOn={() => handleToggle("on")}
          onToggleOff={() => handleToggle("off")}
          disabled={!channelsPagination?.restream_templates.length}
        />
        <div className={styles['button-group-container']}>
          {CHANNEL_FILTERS.map(({ type, label }) => (
            <Button
              key={type}
              className={cn(styles.button, {
                [styles['button_state_active']]: activeFilterType === type,
              })}
              onClick={() => handleFilterChange(type)}
              kind="secondary"
            >
              <span className={styles['button__text']}>{label}</span>
            </Button>
          ))}
          <div className={styles['button-group-container__tooltip']}>
            {!isMobile &&
              <TooltipButton
                text={ChannelCategoriesTooltipText}
                position="bottom-left"
                enableHtml
              />
            }
          </div>
        </div>
      </div>
    </>
  );

  const renderFooter = () => {
    const totalPages = channelsPagination ? Math.ceil(channelsPagination.total / CHANNEL_LIST_ITEMS_PER_PAGE) : 0;
    const displayPages = Math.max(totalPages, 1);

    return (
      <div className={styles.footer}>
        <div className={styles.actions}>
          {selectedChannelIds.length > 0 && (
            <div className={styles['actions__container']}>
              <Button
                kind="secondary"
                type="button"
                className={cn(styles['actions__item'], styles['actions__item_type_dangerous'])}
                onClick={() => setIsDeleteChannelPopup(true)}
              >
                <div className={styles['actions__icon']}>
                  <Icon name="trash-bin" />
                </div>
                <p className={styles['actions__text']}>Удалить</p>
              </Button>
              <Button
                kind="secondary"
                type="button"
                onClick={handleCancelSelection}
                className={styles['footer__button']}
              >
                Отмена
              </Button>
            </div>
          )}
        </div>
        {displayPages > 1 && (
          <Pagination
            currentPage={currentPage}
            pageCount={displayPages}
            onChangePage={handlePageChange}
            paginationClassName={styles['footer__pagination']}
            paginationItemClassName={styles['pagination__item']}
          />
        )}
        <Button
          kind="secondary"
          type="button"
          className={styles['footer__button']}
          onClick={onClose}
        >
          Назад
        </Button>
      </div>
    );
  };

  return (
    <Popup isShow={isOpen} onClose={onClose} maxWidth="1000px" className={styles.popup}>
      <PopupStructure
        className={styles.popup__structure}
        headerComponent={renderHeader()}
        contentComponent={
          <div className={styles['channel-settings']}>
            {channelsPagination?.restream_templates.length
              ? renderChannelColumns(channelsPagination.restream_templates)
              : renderEmptyState()}
          </div>
        }
        footerComponent={renderFooter()}
        isShow={true}
      />
      <MultipleDeleteChannelPopup
        selectedIds={selectedChannelIds}
        onClose={handleCloseDeletePopup}
        onSuccess={handleSuccessDelete}
        isShow={isDeleteChannelPopup}
      />
      {channelToDelete && (
        <DeleteChannelPopup
          channel={channelToDelete}
          onClose={() => setChannelToDelete(null)}
          onDelete={handleDeleteChannel}
          isShow={Boolean(channelToDelete)}
        />
      )}
    </Popup>
  );
};

export default PopupChannelPagination;