import { useState, useEffect } from 'react';
import { useSocketApi } from './useSocketApi';
import { ChannelStatus } from '../components/Stream/Channels/Channels.interface';
import { Channel } from '../features/api/apiSlice';

const getInitialStatuses = (channels: Channel[] = []): ChannelStatus => {
  return channels.reduce((acc: ChannelStatus, channel) => {
    acc[channel.id] = channel.status;
    return acc;
  }, {});
};

export const useChannelStatuses = (channels?: Channel[]) => {
  const [channelStatuses, setChannelStatuses] = useState<ChannelStatus>(
    getInitialStatuses(channels)
  );

  useEffect(() => {
    if (channels) {
      setChannelStatuses(getInitialStatuses(channels));
    }
  }, [channels]);

  useSocketApi({
    onChannelStatusUpdated: ({ id, status }) => {
      setChannelStatuses(prevStatuses => ({
        ...prevStatuses,
        [id]: status
      }));
    }
  });

  return { channelStatuses };
};