import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { DatePicker } from "../../../DatePicker";
import InputLegend from "../../../InputLegend/InputLegend";
import SelectField from "../../../SelectField/SelectField";
import TextAreaField from "../../../TextAreaField/TextAreaField";
import { TimePicker } from "../../../TimePicker";
import TooltipButton from "../../../TooltipButton/TooltipButton";
import YoutubeLatency, { YoutubeLatencySelectorProps } from "../YoutubeLatency/YoutubeLatency";
import { Channel, ChannelLatencyType, useGetChannelCategoriesQuery, usePlannedBroadcastMutation } from "../../../../features/api/apiSlice";
import { useScreenWidth } from "../../../../hooks/useScreenWidth";
import { useNavigate, useParams } from "react-router-dom";
import { Error } from "../../../Error";
import { IOption } from "../../../SelectField/SelectField.interface";
import s from "./YoutubeForm.module.css";

export interface YoutubeFormProps {
  channel?: Channel;
  onClose: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

type BroadcastFormErrorType  = '' | 'both' | 'name' | 'desc';

const YoutubeForm = ({ channel, onClose, setIsLoading }: YoutubeFormProps) => {
  const { isMobile } = useScreenWidth();
  const { id: broadcastId } = useParams();

  const [searchName, setSearchName] = useState("");
  const [datePlanned, setDatePlanned] = useState(new Date());

  const [plannedBroadcastError, setPlannedBroadcastError] = useState('');
  const [broadcastFormErrorType , setBroadcastFormErrorType ] = useState<BroadcastFormErrorType >('');

  const [nameLength, setNameLength] = useState(0);
  const [descLength, setDescLength] = useState(0);

  const { data: categories } = useGetChannelCategoriesQuery({
    channelId: 0,
    categoryName: searchName,
  });

  const [selectedLatency, setSelectedLatency] = useState<YoutubeLatencySelectorProps['selectedLatency']>('normal');

  const broadcastNameText = "Заполните Название и Описание для эфира, и эти данные присвоятся автоматически для интегрированных каналов (YouTube). Так же вы можете изменить Название и Описание для конкретного канала";
  const broadcastDateText = "Внимание! Если вы запланировали эфир и запланированное время ещё не наступило, эфир не запустится даже при наличии сигнала";

  const [selectedCategory, setSelectedCategory] = useState<IOption | null>(() => {
    if (!channel?.category_id || !channel?.category_name) return null;

    return {
      value: channel?.category_id,
      label: channel?.category_name
    };
  });

  const [createPlannedBroadcast, { isLoading }] = usePlannedBroadcastMutation();

  const nameRef = useRef<HTMLTextAreaElement>(null);
  const descRef = useRef<HTMLTextAreaElement>(null);
  const navigate = useNavigate();

  const handleClosePopup = useCallback(() => {
    setPlannedBroadcastError('');
    setBroadcastFormErrorType('');
    onClose();
  }, []);

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNameLength(e.target.value.length);
  };

  const handleDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescLength(e.target.value.length);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (nameRef.current === null || descRef.current === null) {
      return;
    }

    const nameValue = nameRef.current.value;
    const descValue = descRef.current.value;
    const isNameEmpty = nameValue.trim() === '';
    const isDescEmpty = descValue.trim() === '';
    let errorMessage = '';

    setBroadcastFormErrorType('');
    setPlannedBroadcastError('');

    if (isNameEmpty && isDescEmpty) {
      errorMessage = 'Name and Description are empty';
      setBroadcastFormErrorType('both');
    } else if (isNameEmpty) {
      errorMessage = 'Name is empty';
      setBroadcastFormErrorType('name');
    } else if (isDescEmpty) {
      errorMessage = 'Description is empty';
      setBroadcastFormErrorType('desc');
    }

    if (errorMessage) {
      setPlannedBroadcastError('Заполните обязательные поля');
      return;
    }

    createPlannedBroadcast({
      id: Number(broadcastId),
      outer_title: nameValue,
      outer_desc: descValue,
      latency: selectedLatency,
      category_name: selectedCategory?.label,
      category_id: selectedCategory?.value,
      start_time: datePlanned.toISOString()
    })
      .then(() => {
        if (isMobile) {
          navigate("/broadcasts");
        }
        handleClosePopup();
      })
      .catch(() => {
        setPlannedBroadcastError('Ошибка при создании эфира');
      });
  };

  const handleInput = useCallback(() => {
    setBroadcastFormErrorType('');
    setPlannedBroadcastError('');
  }, []);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <form id="planned-form" className={s['broadcast-edit__form']} onSubmit={handleSubmit}>
      <InputLegend legend={<>Название эфира {!isMobile && <TooltipButton position="bottom-right" text={broadcastNameText} />}</>}>
        <TextAreaField
          isError={broadcastFormErrorType === 'both' || broadcastFormErrorType === 'name'}
          placeholder="Название эфира*"
          maxLength={100}
          type="text"
          onChange={handleNameChange}
          usedChars={nameLength}
          ref={nameRef}
          onInput={handleInput}
        />
      </InputLegend>

      <InputLegend legend={<>Описание эфира {!isMobile && <TooltipButton position="bottom-right" text={broadcastNameText} />}</>}>
        <TextAreaField
          isError={broadcastFormErrorType === 'both' || broadcastFormErrorType === 'desc'}
          placeholder="Описание эфира*"
          maxLength={5000}
          type="text"
          onChange={handleDescChange}
          usedChars={descLength}
          ref={descRef}
          onInput={handleInput}
        />
      </InputLegend>

      <InputLegend legend="Категория">
        <SelectField
          placeholder="(Не установлена)"
          selectedOption={selectedCategory}
          selectedOptionHandler={setSelectedCategory}
          value={searchName}
          setValue={setSearchName}
          options={categories?.map(category => ({
            value: category.id,
            label: category.name,
            imageSrc: category.box_art_url
          }))}
        />
      </InputLegend>

      <div className={s["broadcast-edit__field-section"]}>
        <InputLegend legend={<>Дата начала {!isMobile && <TooltipButton text={broadcastDateText} />}</>}>
          <DatePicker
            selected={datePlanned}
            onChange={(datePlanned) => setDatePlanned(datePlanned!)}
          />
        </InputLegend>

        <InputLegend legend={<>Время начала {!isMobile && <TooltipButton position="top-left" text={broadcastDateText} />}</>}>
          <TimePicker
            value={datePlanned}
            onChange={(value) => {
              const [hour, minutes] = value ? (value as string).split(':') : ["00", "00"];
              const newDate = new Date(datePlanned);
              newDate.setHours(parseInt(hour, 10));
              newDate.setMinutes(parseInt(minutes, 10));
              setDatePlanned(newDate);
            }}
          />
        </InputLegend>
      </div>

      <YoutubeLatency
        selectedLatency={selectedLatency}
        handleLatencyChange={(value: ChannelLatencyType) => setSelectedLatency(value)}
      />

      <Error>{plannedBroadcastError}</Error>
    </form>
  );
};

export default YoutubeForm;