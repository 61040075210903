import { MouseEvent, useRef, useEffect } from "react";
import { ComponentProps } from "react";
import s from "./Popup.module.css";
import { useScreenWidth } from "../../hooks/useScreenWidth";

let openPopupsCount = 0;

export type Props = ComponentProps<'div'> & {
    onClose: () => void;
    isShow: boolean;
    maxWidth?: string;
};

export function Popup({ onClose, isShow, maxWidth = "430px", children, ...restProps }: Props) {
    const { isMobile } = useScreenWidth();
    const popupRef = useRef<HTMLDivElement>(null);
    const popupBodyStyles = { maxWidth: isMobile ? "100%" : maxWidth };

    useEffect(() => {
        if (isShow) {
            openPopupsCount++;

            if (openPopupsCount === 1) {
                const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
                document.body.style.overflow = 'hidden';
                document.body.style.paddingRight = `${scrollbarWidth}px`;
            }
        }

        return () => {
            if (isShow) {
                openPopupsCount--;

                if (openPopupsCount === 0) {
                    document.body.style.overflow = '';
                    document.body.style.paddingRight = '';
                }
            }
        };
    }, [isShow]);

    function handleClose(event: MouseEvent<HTMLDivElement>) {
        if (event.target !== popupRef.current) {
            return;
        }
        onClose();
    }

    return (
        <div
            {...restProps}
            className={`${s['popup']} ${isShow ? s['popup_show'] : ''}`}
            onMouseDown={handleClose}
            ref={popupRef}
        >
            <div className={s['popup__body']} style={popupBodyStyles}>
                {children}
            </div>
        </div>
    );
}