import React, { FC, FormEvent, useCallback, useRef, useState } from 'react';

// * Components
import InputLegend from '../../InputLegend';
import { InputField } from '../../InputField';
import { Error } from '../../Error';
import { PasswordField } from '../../PasswordField';

// * Hooks
import { useCreateChannelMutation } from '../../../features/api/apiSlice';

// * Local
import { ChannelFormProps } from './PlatformForm.interface';
import styles from "./PlatformForm.module.css";
import TextAreaField from '../../TextAreaField';
import PlatformInstruction from '../PlatformInstruction/PlatformInstruction';

// * Helpers
const getDefaultErrors = () => ({ name: '', url: '', key: '' });

const ChannelForm: FC<ChannelFormProps> = ({
  id,
  selectedPlatform,
  closePopup
}) => {
  const [ createChannel ] = useCreateChannelMutation();

  // * Template refs
  const nameRef = useRef<HTMLTextAreaElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);
  const keyRef = useRef<HTMLInputElement>(null);

  // * States
  const [ errorFields, setErrorFields ] = useState(getDefaultErrors());
  const [ errorText, setErrorText ] = useState('');
  const [ nameLength, setNameLength ] = useState(0);

  // * Handlers
  const  handleInput = useCallback(() => {
    setErrorFields(getDefaultErrors());
    setErrorText('');
  }, []);

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNameLength(e.target.value.length);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const name = nameRef.current?.value;
    const url = urlRef.current?.value;
    const key = keyRef.current?.value;

    if (name === null || url === null || key === null) return;

    const isNameEmpty = name === '';
    const isUrlEmpty = url === '';
    const isKeyEmpty = key === '';

    if (isNameEmpty || isUrlEmpty || isKeyEmpty) {
      setErrorFields({
        name: isNameEmpty ? 'Пустое поле' : '',
        url: isUrlEmpty ? 'Пустое поле' : '',
        key: isKeyEmpty ? 'Пустое поле' : ''
      });

      return setErrorText('Заполните обязательные поля');
    }

    // * platform = 'other' используется для индикации, что в формочке выбрано "Другое"
    // * но в теле запроса не должно быть platform со значением "other"
    const platform = (selectedPlatform?.platform && selectedPlatform.platform !== 'other')
      ? selectedPlatform.platform
      : undefined;

    try {
      await createChannel({
        name: name!,
        target_url: url!,
        key: key!,
        platform
      }).unwrap();
      closePopup();
    } catch (error: any) {
      const isInvalidUrl = error.data?.errors === 'INVALID_TARGET_URL';
      const errorMessage = isInvalidUrl
        ? 'Неверный URL канала'
        : 'Данную ссылку нельзя использовать для создания канала';

      setErrorText(errorMessage);
      setErrorFields(prev => ({ ...prev, url: errorMessage }));
    }
  };

  return (
    <React.Fragment>
      {selectedPlatform &&
        <PlatformInstruction selectedPlatform={ selectedPlatform } />
      }

      <form id={id} onSubmit={handleSubmit} className={styles['form']} autoComplete="off">
        <InputLegend legend='Название канала'>
          <TextAreaField isError={Boolean(errorFields.name)} maxLength={100} placeholder="Название*" onInput={handleInput} onChange={handleNameChange} usedChars={nameLength} ref={nameRef} />
        </InputLegend>

        <InputLegend legend='URL канала'>
          <InputField isError={Boolean(errorFields.url)} placeholder="rtmp://example.com/live*" onInput={handleInput} ref={urlRef} />
        </InputLegend>

        <InputLegend legend='Ключ канала'>
          <PasswordField isError={Boolean(errorFields.key)} icon="toggle" placeholder="Ключ*" onInput={handleInput} ref={keyRef} autoComplete="new-password" />
        </InputLegend>
      </form>

      <Error className={styles['error']}>{errorText && errorText}</Error>
    </React.Fragment>
  );
};

export default ChannelForm;