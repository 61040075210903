import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react";

// * Components
import { InputField } from "../InputField";
import Icon from "../Icon";

//* Props
import { IOption, SelectFieldProps } from "./SelectField.interface";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./SelectField.module.css";
import { useClickOutside } from "../../hooks/useClickOutside";
import useMeasure from "react-use-measure";

const safeDropdownBottomOffset = 25; // Pixels
const DEFAULT_MAX_HEIGHT = 300;
const MIN_HEIGHT = 100;

const SelectField: FC<SelectFieldProps> = ({
  placeholder,
  options,
  selectedOption,
  selectedOptionHandler,
  value,
  setValue,
  isDisabled,
  error,
  onInput,
  className,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const selectFieldRef = useRef<HTMLDivElement>(null);
  const [optionsRef, optionsBounds] = useMeasure();

  const availableOptionsHeight = document.documentElement.clientHeight - (optionsBounds.top + safeDropdownBottomOffset);

  const calculateMaxHeight = useCallback(() => {
    if (!optionsBounds.top) return DEFAULT_MAX_HEIGHT;

    const maxHeight = Math.min(
      availableOptionsHeight,
      DEFAULT_MAX_HEIGHT
    );

    return Math.max(maxHeight, MIN_HEIGHT);
  }, [optionsBounds.top, availableOptionsHeight]);

  const shouldShowAbove = availableOptionsHeight < DEFAULT_MAX_HEIGHT;

  const arrowClasses = cn(styles.select__arrow, {
    [styles.select__arrow_rotated]: isOptionsOpen,
    [styles.select__arrow_disabled]: isDisabled,
  });

  const handleOptionsDisplay = useCallback(() => {
    if (isDisabled) return;
    setIsOptionsOpen((isOptionsOpen) => !isOptionsOpen);
  }, [isDisabled]);

  const handleSelectOption = (option: IOption) => {
    if (selectedOption?.value === option.value) {
      // При повтором клике по выбранному option сбрасываем его
      selectedOptionHandler({ value: "", label: "" });
      setValue("");
    } else {
      selectedOptionHandler(option);
    }

    setIsOptionsOpen(false);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isOptionsOpen) setIsOptionsOpen(true);

    const value = e.target.value;

    if (value) {
      setValue?.(value);
    } else {
      selectedOptionHandler({ value: "", label: "" });
    }
    onInput?.(e);
  };

  useClickOutside(selectFieldRef, () => {
    setIsOptionsOpen(false);
  });

  useEffect(() => {
    if (selectedOption) {
      setValue(selectedOption.label);
    }
  }, [selectedOption]);

  return (
    <div className={styles.select} ref={selectFieldRef}>
      <div className={styles.select__field} onClick={handleOptionsDisplay}>
        <InputField
          className={cn(styles["select__field-input"], className)}
          defaultValue={selectedOption?.label}
          value={value}
          onInput={handleInput}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isError={Boolean(error)}
          error={error}
        />
        <div className={arrowClasses} onClick={handleOptionsDisplay}>
          <Icon name="arrow-down" />
        </div>
      </div>

      {(isOptionsOpen && Boolean(options?.length)) && (
        <div
          className={cn(styles.select__options, {
            [styles.select__options_position_top]: shouldShowAbove
          })}
          ref={optionsRef}
          style={{
            maxHeight: `${calculateMaxHeight()}px`
          }}
        >
          <div className={styles["select__options-container"]}>
            {options?.map(({ value, label, imageSrc }) => {
              const imageElement = imageSrc && (
                <div className={styles["select__option-image"]}>
                  <img src={imageSrc} alt={label} />
                </div>
              );

              return (
                <label
                  key={value}
                  className={cn(styles.select__option, {
                    [styles.select__option_selected]: value === selectedOption?.value,
                  })}
                >
                  <input
                    id={value}
                    name="select"
                    type="radio"
                    value={value}
                    onChange={() => handleSelectOption({ value, label })}
                  />
                  {imageElement}
                  <p>{label}</p>
                </label>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectField;
