import { FormEvent, useCallback, useRef, useState } from "react";
import cn from "classnames";
import { Broadcast, useUpdateBroadcastMutation } from "../../features/api/apiSlice";
import { Button } from "../Button";
import { ButtonGroup } from "../ButtonGroup";
import { Popup } from "../Popup";
import { PopupTitle } from "../PopupTitle";
import { Error } from "../Error";
import s from "./EditBroadcastPopup.module.css";
import { DatePicker } from "../DatePicker";
import { TimePicker } from "../TimePicker";
import TooltipButton from "../TooltipButton";
import PopupStructure from "../PopupStructure";
import InputLegend from "../InputLegend";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import TextAreaField from "../TextAreaField";

type Props = {
    broadcast: Broadcast;
    onClose: () => void;
};

export function EditBroadcastPopup({ broadcast, onClose }: Props) {
    const { isMobile } = useScreenWidth();
    const [ updateBroadcast ] = useUpdateBroadcastMutation();
    const [ date, setDate ] = useState(new Date(broadcast?.start_at));
    const [ editBroadcastError, setEditBroadcastError ] = useState('');
    const [ editErrors, setEditErrors ] = useState('');
    const editBroadcastNameRef = useRef<HTMLTextAreaElement>(null);
    const editBroadcastDescRef = useRef<HTMLTextAreaElement>(null);
    const [nameLength, setNameLength] = useState(broadcast?.name?.length || 0);
    const [descLength, setDescLength] = useState(broadcast?.description?.length || 0);
    const handleClosePopupEdit = useCallback(() => {
        setEditBroadcastError('');
        setEditErrors('');
        onClose();
    }, []);

    const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNameLength(e.target.value.length);
    };

    const handleDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescLength(e.target.value.length);
    };

    const broadcastNameText = "Заполните Название и Описание для эфира, и эти данные присвоятся автоматически для интегрированных каналов (YouTube, Twitch, Trovo, ВКонтакте). Так же вы можете изменить Название и Описание для конкретного канала";
    const broadcastDateText = "Внимание! Если вы запланировали эфир и запланированное время ещё не наступило, эфир не запустится даже при наличии сигнала";

    function handleSubmitEditBroadcastForm(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!broadcast) return;
        if (editBroadcastNameRef.current === null || editBroadcastDescRef.current === null) {
            return;
        }
        const nameValue = editBroadcastNameRef.current.value;
        const descValue = editBroadcastDescRef.current.value;
        const isNameEmpty = nameValue === '';
        const isError = isNameEmpty;
        setEditErrors(isNameEmpty ? 'Name is empty' : '');
        if (isError) {
            return setEditBroadcastError('Заполните обязательные поля');
        }
        updateBroadcast({
            id: broadcast.id,
            body: {
                name: nameValue,
                description: descValue,
                start_at: date.toISOString(),
                rtmp_key: broadcast.rtmp_key
            }
        });
        handleClosePopupEdit();
    }
    return (
        <Popup isShow={Boolean(broadcast)} onClose={handleClosePopupEdit} maxWidth="530px">
            <div className={cn(s['broadcast-edit'], s['popup'])}>
                <PopupStructure
                    headerComponent={
                        <PopupTitle className={s['broadcast-edit__title']}>
                            Редактирование эфира
                            {!isMobile &&
                                <TooltipButton
                                    position="bottom-center"
                                    text="Запланированный Эфир начнется согласно выставленной Дате и Времени."
                                />
                            }

                        </PopupTitle>
                    }
                    contentComponent={
                        <form id="edit-broadcast-form" className={s['broadcast-edit__form']} onSubmit={handleSubmitEditBroadcastForm}>
                            <InputLegend legend={<>Название эфира {!isMobile && <TooltipButton position="bottom-right" text={broadcastNameText}/>}</>}>
                                <TextAreaField
                                    isError={Boolean(editErrors)}
                                    placeholder="Название эфира*"
                                    maxLength={100}
                                    type="text"
                                    defaultValue={broadcast?.name}
                                    ref={editBroadcastNameRef}
                                    onChange={handleNameChange}
                                    usedChars={nameLength}
                                />
                            </InputLegend>

                            <InputLegend legend={<>Описание эфира {!isMobile && <TooltipButton text={broadcastNameText}/>}</>}>
                                <TextAreaField
                                    placeholder="Описание эфира"
                                    maxLength={5000}
                                    type="text"
                                    defaultValue={broadcast?.description}
                                    ref={editBroadcastDescRef}
                                    onChange={handleDescChange}
                                    usedChars={descLength}
                                />
                            </InputLegend>

                            <div className={s["broadcast-edit__field-section"]}>
                                <InputLegend legend={<>Дата начала {!isMobile && <TooltipButton position="top-right" text={broadcastDateText}/>}</>}>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => setDate(date!)}
                                    />
                                </InputLegend>

                                <InputLegend legend={<>Время начала {!isMobile && <TooltipButton position="top-left" text={broadcastDateText}/>}</>}>
                                    <TimePicker
                                        value={date}
                                        onChange={(value) => {
                                            const [ hour, minutes ] = value ? (value as string).split(':') : ["00", "00"];
                                            date.setHours(parseInt(hour, 10));
                                            date.setMinutes(parseInt(minutes, 10));
                                            setDate(date);
                                        }}
                                    />
                                </InputLegend>
                            </div>

                            <Error>{editBroadcastError}</Error>
                        </form>
                    }
                    footerComponent={
                        <ButtonGroup className={s['broadcast-edit__button-group']}>
                            <Button kind="secondary" type="button" onClick={handleClosePopupEdit}>Отмена</Button>
                            <Button form="edit-broadcast-form" kind="primary" type="submit">Сохранить</Button>
                        </ButtonGroup>
                    }
                />
            </div>
        </Popup>
    );
}