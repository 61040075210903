import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ChannelError,
  ChannelLatencyType,
  useLazyGetChannelStatusQuery,
  useGetChannelCategoriesQuery,
  useUpdateChannelMutation,
  ChannelCategory,
  useGetDefaultTemplatesQuery,
  ChannelTemplate,
} from "../../features/api/apiSlice";
import { Button } from "../Button";
import { ButtonGroup } from "../ButtonGroup";
import { Error } from "../Error";
import { InputField } from "../InputField";
import InputLegend from "../InputLegend";
import { Platform } from "../../features/api/apiSlice";
import { Popup } from "../Popup";
import PopupStructure from "../PopupStructure";
import { PopupTitle } from "../PopupTitle";
import { Checkbox } from "../Checkbox";
import SelectField from "../SelectField";
import { IYoutubeChannelLatencies } from "./EditPlatformPopup.data";
import { EditPlatformPopupProps } from "./EditPlatformPopup.interface";
import cn from "classnames";
import styles from "./EditPlatformPopup.module.css";
import { IOption } from "../SelectField/SelectField.interface";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import TextAreaField from "../TextAreaField";
import { Link } from "react-router-dom";
import Chip from "../Chip/Chip";

function getDefaultErrors() {
  return { outerTitle: '', outerDescription: '', channelKey: '' };
}

const withoutDescriptionPlatforms: Platform[] = ["twitch", "trovo", "gg"];
const withoutKeyPlatforms: Platform[] = ["gg"];
const withCategoriesPlatforms: Platform[] = ["twitch", "trovo", "youtube", "gg", "vk"];

const getMaxLength = (platform: Platform) => {
  switch (platform) {
    case 'twitch':
      return 140;
    case 'vk':
      return 128;
    default:
      return 100;
  }
};

const EditPlatformPopup: React.FC<EditPlatformPopupProps> = ({
  channel,
  isBroadcastOnline,
  tariff,
  ...popupProps
}) => {
  const [searchName, setSearchName] = useState("");
  const [subcategorySearchName, setSubcategorySearchName] = useState(channel?.subcategory_name ?? "");
  const [filteredSubcategories, setFilteredSubcategories] = useState<ChannelCategory[]>([]);
  const debouncedSearchName = useDebouncedValue(searchName, 500);
  const hasCategories = channel.platform && withCategoriesPlatforms.includes(channel.platform);
  const { data: categories } = useGetChannelCategoriesQuery({
    channelId: channel.id,
    categoryName: debouncedSearchName.trim(),
  }, { skip: !hasCategories });
  const [updateChannel, { isLoading }] = useUpdateChannelMutation();
  const [getChannelStatus, { data: channelStatus, isLoading: isChannelStatusLoading }] = useLazyGetChannelStatusQuery();
  const { data: defaultTemplates } = useGetDefaultTemplatesQuery({ chanelId: channel.id });

  const channelKeyRef = useRef<HTMLInputElement>(null);
  const checkboxInputRef = useRef<HTMLInputElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<IOption | null>(initCategory(channel.category_id, channel.category_name));
  const [selectedSubcategory, setSelectedSubcategory] = useState<IOption | null>(initCategory(channel.subcategory_id, channel.subcategory_name));
  const subcategories = categories?.find((category) => category.id === selectedCategory?.value)?.subcategories;

  const defaultChannelKey = channel.error === ChannelError.KEY_REQUIRED ? "" : channel.key;

  const [channelLatency, setChannelLantency] = useState<ChannelLatencyType | undefined>(channel.latency);
  const [errorFields, setErrorFields] = useState(getDefaultErrors());
  const [errorText, setErrorText] = useState('');

  const [isChipTitle, setIsChipTitle] = useState(false);
  const [isChipDescription, setIsChipDescription] = useState(false);

  const [titleValue, setTitleValue] = useState(channel.outer_title ?? '');
  const [descriptionValue, setDescriptionValue] = useState(channel.outer_desc ?? '');

  const calculateLengthWithSuffix = (value: string, maxLength: number, suffix?: string) => {
    const suffixLength = suffix ? suffix.length + 1 : 0;
    return value.length + suffixLength > maxLength;
  };

  const calculateTotalLength = (value: string, isChipEnabled: boolean, suffix?: string) => {
    return value.length + (isChipEnabled && suffix ? suffix.length + 1 : 0);
  };

  const getRemainingChars = (value: string, maxLength: number, suffix?: string) => {
    const suffixLength = suffix ? suffix.length + 1 : 0;
    return maxLength - (value.length + suffixLength);
  };

  const handleInput = useCallback(() => {
    setErrorFields(getDefaultErrors());
    setErrorText('');
  }, []);

  const handleTitleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const maxLength = getMaxLength(channel.platform!);
    const remaining = getRemainingChars(newValue, maxLength, isChipTitle ? defaultTemplates?.title : undefined);

    if (remaining >= 0) {
      setTitleValue(newValue);
      handleInput();
    }
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const remaining = getRemainingChars(newValue, 5000, isChipDescription ? defaultTemplates?.desc : undefined);

    if (remaining >= 0) {
      setDescriptionValue(newValue);
      handleInput();
    }
  };

  function initCategory(value: string | undefined, label: string | undefined) {
    if (!hasCategories) return null;
    if (!value || !label) return null;

    return {
      value,
      label
    };
  }

  function handleFilterCategoriesByQuery(query: string, categories?: ChannelCategory[]) {
    if (!categories) return;

    return categories.filter(category => category.name.toLowerCase().includes(query.toLowerCase().trim()));
  }

  function filteredCategories(query: string, handleUpdate: (value: ChannelCategory[]) => void, categories?: ChannelCategory[]) {
    if (!categories) return;

    const filterCategories = handleFilterCategoriesByQuery(query, categories);
    handleUpdate(filterCategories ?? []);
  }

  function getCategories(filterArray: ChannelCategory[], searchText: string, originArray?: ChannelCategory[]) {
    if (!filterArray.length && !originArray?.length) return [];

    let responseArray: ChannelCategory[];

    if (searchText) {
      responseArray = [...filterArray];
    } else {
      responseArray = originArray ?? [];
    }

    return responseArray.map(({ id: value, name: label, box_art_url: imageSrc }) => ({
      value,
      label,
      imageSrc
    }));
  }

  const handleTemplateTitle = (templates: ChannelTemplate, channelTitle: string) => {
    if (!templates.title || !channelTitle.endsWith(templates.title)) return;

    setIsChipTitle(true);
    setTitleValue(channelTitle.slice(0, -(templates.title.length + 1)).trim());
  };

  const handleTemplateDescription = (templates: ChannelTemplate, channelDesc: string) => {
    if (!templates.desc || !channelDesc.endsWith(templates.desc)) return;

    setIsChipDescription(true);
    setDescriptionValue(channelDesc.slice(0, -(templates.desc.length + 1)).trim());
  };

  const handleFreeAccountTitle = (templates: ChannelTemplate, platform: Platform) => {
    if (!templates.title) return;

    setIsChipTitle(true);
    const maxLength = getMaxLength(platform);
    if (calculateLengthWithSuffix(titleValue, maxLength, templates.title)) {
      setTitleValue(titleValue.slice(0, maxLength - (templates.title.length + 1)));
    }
  };

  const handleFreeAccountDescription = (templates: ChannelTemplate) => {
    if (!templates.desc) return;

    setIsChipDescription(true);
    if (calculateLengthWithSuffix(descriptionValue, 5000, templates.desc)) {
      setDescriptionValue(descriptionValue.slice(0, 5000 - (templates.desc.length + 1)));
    }
  };

  const handleChangeIntegratedChannel = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFreeAccount = tariff === 'R2' && defaultTemplates;
    const maxTitleLength = getMaxLength(channel.platform!);

    let finalTitleValue = titleValue;
    let finalDescValue = descriptionValue;

    if (isFreeAccount && defaultTemplates?.title) {
      finalTitleValue = `${titleValue} ${defaultTemplates.title}`.trim();
      setIsChipTitle(true);
    }

    if (isFreeAccount && defaultTemplates?.desc) {
      finalDescValue = `${descriptionValue} ${defaultTemplates.desc}`.trim();
      setIsChipDescription(true);
    }

    if (!isFreeAccount) {
      finalTitleValue = titleValue + (isChipTitle ? ` ${defaultTemplates?.title}` : '');
      finalDescValue = descriptionValue + (isChipDescription ? ` ${defaultTemplates?.desc}` : '');

      const isTitleEmpty = !finalTitleValue.trim();
      const isDescriptionEmpty = !finalDescValue.trim();
      const needsDescription = !withoutDescriptionPlatforms.includes(channel.platform!);

      const hasEmptyRequiredFields = isTitleEmpty || (needsDescription && isDescriptionEmpty);

      if (hasEmptyRequiredFields) {
        setErrorFields({
          outerTitle: isTitleEmpty ? 'Пустое поле' : '',
          outerDescription: isDescriptionEmpty ? 'Пустое поле' : '',
          channelKey: ''
        });
        setErrorText('Заполните обязательные поля');
        return;
      }
    }

    const hasTitleLengthError = finalTitleValue.length > maxTitleLength;
    const hasDescriptionLengthError = finalDescValue.length > 5000;

    if (hasTitleLengthError) {
      setErrorFields({ ...getDefaultErrors(), outerTitle: 'Превышен лимит символов' });
      setErrorText('Превышен лимит символов в названии эфира');
      return;
    }

    if (hasDescriptionLengthError) {
      setErrorFields({ ...getDefaultErrors(), outerDescription: 'Превышен лимит символов' });
      setErrorText('Превышен лимит символов в описании эфира');
      return;
    }

    const channelKey = channelKeyRef.current?.value?.trim();
    const vkWallpost = checkboxInputRef.current?.checked;

    updateChannel({
      id: channel.id,
      outer_title: finalTitleValue,
      outer_desc: finalDescValue,
      key: channelKey,
      vk_wallpost: vkWallpost,
      category_id: selectedCategory?.value,
      category_name: selectedCategory?.label,
      subcategory_id: Boolean(subcategories) ? selectedSubcategory?.value : "",
      subcategory_name: Boolean(subcategories) ? selectedSubcategory?.label : ""
    }).then(() => {
      popupProps.onClose();
    });
  };

  const handleChangeLatency = (latency: ChannelLatencyType) => {
    if (channelStatus === "active" || isChannelStatusLoading) return;

    updateChannel({
      id: channel.id,
      latency,
    }).then(() => {
      setChannelLantency(latency);
    });
  };

  useEffect(() => {
    if (!defaultTemplates || !channel.outer_title) return;

    handleTemplateTitle(defaultTemplates, channel.outer_title);

    if (!withoutDescriptionPlatforms.includes(channel.platform!)) {
      handleTemplateDescription(defaultTemplates, channel.outer_desc || '');
    }
  }, [defaultTemplates, channel]);

  useEffect(() => {
    if (!defaultTemplates || tariff !== 'R2') return;

    handleFreeAccountTitle(defaultTemplates, channel.platform!);
    handleFreeAccountDescription(defaultTemplates);
  }, [tariff, defaultTemplates, titleValue, descriptionValue, channel.platform]);

  useEffect(() => {
    if (channel.platform === "youtube") {
      getChannelStatus({ channelId: channel.id });
    }
  }, [channel]);

  useEffect(() => {
    if (searchName && !subcategories) {
      setSelectedSubcategory(null);
      setSubcategorySearchName("");
    }
  }, [subcategories]);

  useEffect(() => {
    filteredCategories(subcategorySearchName, setFilteredSubcategories, subcategories);
  }, [subcategories, subcategorySearchName]);

  return (
    <Popup {...popupProps} maxWidth="530px">
      <PopupStructure
        headerComponent={
          <PopupTitle onClose={popupProps.onClose}>Редактировать канал</PopupTitle>
        }
        contentComponent={
          <React.Fragment>
            <form
              id="edit-platform-form"
              onSubmit={handleChangeIntegratedChannel}
              className={styles["form"]}
            >
              <InputLegend legend="Название канала">
                <InputField
                  placeholder="Название*"
                  onInput={handleInput}
                  defaultValue={channel.name}
                  readOnly
                />
              </InputLegend>

              <InputLegend legend="Название эфира" htmlFor="broadcastTitle">
                <div className={styles["field-container"]}>
                  {isChipTitle && defaultTemplates?.title && (
                    <div className={styles["chips-container"]}>
                      <Chip
                        text={defaultTemplates.title}
                        isTooltip={tariff === "R2"}
                        tooltipContent={
                          <div>
                            Улучшите свой <Link to="/tariffs">тариф</Link> для
                            удаления данного блока
                          </div>
                        }
                        onDelete={() => setIsChipTitle(false)}
                      />
                    </div>
                  )}
                  <TextAreaField
                    id="broadcastTitle"
                    isError={Boolean(errorFields.outerTitle)}
                    placeholder="Название эфира*"
                    maxLength={getMaxLength(channel.platform!)}
                    value={titleValue}
                    onChange={handleTitleChange}
                    usedChars={calculateTotalLength(titleValue, isChipTitle, defaultTemplates?.title)}
                  />
                </div>
              </InputLegend>

              {channel.platform &&
                !withoutDescriptionPlatforms.includes(channel.platform) && (
                  <InputLegend legend="Описание эфира" htmlFor="broadcastDesc">
                    <div className={styles["field-container"]}>
                      {isChipDescription && defaultTemplates?.desc && (
                        <div className={styles["chips-container"]}>
                          <Chip
                            text={defaultTemplates.desc}
                            isTooltip={tariff === "R2"}
                            tooltipContent={
                              <div>
                                Улучшите свой <Link to="/tariffs">тариф</Link> для
                                удаления данного блока
                              </div>
                            }
                            onDelete={() => setIsChipDescription(false)}
                          />
                        </div>
                      )}
                      <TextAreaField
                        id="broadcastDesc"
                        isError={Boolean(errorFields.outerDescription)}
                        placeholder="Описание эфира*"
                        maxLength={5000}
                        value={descriptionValue}
                        onChange={handleDescriptionChange}
                        usedChars={calculateTotalLength(descriptionValue, isChipDescription, defaultTemplates?.desc)}
                      />
                    </div>
                  </InputLegend>
                )}
              {hasCategories && (
                <InputLegend legend="Категория">
                  {channel.platform === "vk" && isBroadcastOnline && channel.enabled ?
                    <SelectField
                      placeholder="(Не установлена)"
                      selectedOption={selectedCategory}
                      selectedOptionHandler={setSelectedCategory}
                      value={searchName}
                      setValue={setSearchName}
                      isDisabled={true}
                    />
                    :
                    <SelectField
                      placeholder="(Не установлена)"
                      selectedOption={selectedCategory}
                      selectedOptionHandler={setSelectedCategory}
                      value={searchName}
                      setValue={setSearchName}
                      options={categories?.map(({ id: value, name: label, box_art_url: imageSrc }) => ({
                        value,
                        label,
                        imageSrc
                      }))}
                    />}
                </InputLegend>
              )}

              {Boolean(subcategories) && (
                <InputLegend legend="Подкатегория">
                  {channel.platform === "vk" && isBroadcastOnline && channel.enabled ?
                    <SelectField
                      placeholder="(Не установлена)"
                      selectedOption={selectedSubcategory}
                      selectedOptionHandler={setSelectedSubcategory}
                      value={subcategorySearchName}
                      setValue={setSubcategorySearchName}
                      isDisabled={true}
                    />
                    :
                    <SelectField
                      placeholder="(Не установлена)"
                      selectedOption={selectedSubcategory}
                      selectedOptionHandler={setSelectedSubcategory}
                      value={subcategorySearchName}
                      setValue={setSubcategorySearchName}
                      options={getCategories(filteredSubcategories, subcategorySearchName, subcategories)}
                    />}
                </InputLegend>
              )}

              {(channel.platform && withoutKeyPlatforms.includes(channel.platform)) && (
                <InputLegend legend="Ключ трансляции">
                  <InputField
                    isError={Boolean(errorFields.channelKey)}
                    placeholder="Ключ трансляции*"
                    onInput={handleInput}
                    ref={channelKeyRef}
                    defaultValue={defaultChannelKey}
                  />
                </InputLegend>
              )}

              {channel.platform === "vk" &&
                <div className={styles.checkbox}>
                  <Checkbox
                    value={channel.vk_wallpost}
                    inputRef={checkboxInputRef}
                  >
                    Публиковать эфир на стену
                  </Checkbox>
                </div>
              }

              {channel.platform === "youtube" && (
                <div className={styles.form__section}>
                  <h6 className={styles.form__heading}>Уровень задержки</h6>

                  <div className={cn(styles.form__latency, {
                    [styles.form__latency_disabled]: channelStatus === "active" || isChannelStatusLoading,
                  })}
                  >
                    {IYoutubeChannelLatencies.map(({ value, label }) => (
                      <div
                        className={cn(styles["form__latency-button"], {
                          [styles["form__latency-button_active"]]: channelLatency === value,
                        })}
                        key={value}
                        onClick={() => handleChangeLatency(value)}
                      >
                        {label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </form>
            <Error className={styles['error']}>{errorText}</Error>
          </React.Fragment>
        }
        footerComponent={
          <ButtonGroup>
            <Button kind="secondary" type="button" onClick={popupProps.onClose}>Отмена</Button>
            <Button form="edit-platform-form" kind="primary" type="submit">{isLoading ? "Загрузка..." : "Сохранить"}</Button>
          </ButtonGroup>
        }
      />
    </Popup>
  );
};

export default EditPlatformPopup;
