import cn from "classnames";
import React, { useRef, useState } from "react";
import { Platform } from "../../../../features/api/apiSlice";

// * Data
import { channelErrors } from "./ConnectedChannel.data";

// * Hooks
import { useParams } from "react-router-dom";
import { useChannelAuth } from "../../../../hooks/useChannelAuth";
import { useClickOutside } from "../../../../hooks/useClickOutside";

// * Components
import { Switch } from "../../../Switch";
import StreamStatus from "../../../StreamStatus";
import Icon from "../../../Icon";
import PlatformIcon from "../../../PlatformIcon";

// * Local
import { ConnectedChannelProps } from './ConnectedChannel.interface';
import styles from './ConnectedChannel.module.css';
import { ChannelError } from "../../../../features/api/apiSlice";
import { Checkbox } from "../../../Checkbox";

const ConnectedChannel: React.FC<ConnectedChannelProps> = ({
  channel,
  status = "disabled",
  onToggleEnabled,
  onEdit,
  onDelete,
  onCheckboxChange,
  showCheckbox = false,
  isChecked = false
}) => {

  const params = useParams<{id: string}>();
  const channelRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { handleLoginViaPlatform } = useChannelAuth(Number(params.id));
  const isChannelError = channel.error && channel.error !== ChannelError.STREAM_BITRATE_WARNING;

  const handleUpdateToken = (platform?: Platform) => {
    if (!platform) return;

    handleLoginViaPlatform(platform);
    setIsMenuOpen(false);
  };

  useClickOutside(channelRef, () => {
    setIsMenuOpen(false);
  });

  return (
    <div
      className={cn(styles.channel, { [styles.channel_error]: isChannelError, [styles["channel_with-checkbox"]]: showCheckbox })}
      ref={channelRef}
    >
      {showCheckbox && (
        <div className={styles.channel__checkbox}>
          <Checkbox
            onChange={onCheckboxChange}
            value={isChecked}
          />
        </div>
      )}

      {isChannelError && (
        <div className={styles.channel__error}>
          <Icon name="channel-error" />
          <p style={{ marginRight: '10px' }}>{channelErrors[channel.error!]}</p>
          {channel.error === ChannelError.TOKEN_AUTH_ERROR && (
            <button
              className={styles["channel_refresh-button"]}
              onClick={() => handleUpdateToken(channel.platform)}
            >
              <Icon name="refresh" />
              Обновить токен
            </button>
          )}
        </div>
      )}

      <div className={styles.channel__platform}>
        <PlatformIcon platform={channel.platform} />
      </div>

      <div className={styles.channel__name}>
        {channel.name}
      </div>

      <div className={styles.channel__buttons}>
        <StreamStatus isStatusText={false} status={status} />

        <Switch
          onChange={() => onToggleEnabled?.(channel)}
          checked={channel.enabled}
        />

        <div className={styles['channel__buttons-edit']}>
          <button
            className={cn(
              styles['button-edit'],
              {[styles['button-edit_active']]: isMenuOpen }
            )}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div className={styles['button-edit__dot']} />
            <div className={styles['button-edit__dot']} />
            <div className={styles['button-edit__dot']} />
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <div className={styles.dropdown}>
          {channel.error && channel.error === ChannelError.TOKEN_AUTH_ERROR ? (
            <button
              className={styles.dropdown__item}
              onClick={() => handleUpdateToken(channel.platform)}
            >
              <div className={styles["dropdown__item-icon"]}>
                <Icon name="refresh" />
              </div>

              <p className={styles["dropdown__item-text"]}>Обновить токен</p>
            </button>
          ) : (
            <button className={styles.dropdown__item} onClick={() => onEdit?.(channel)}>
              <div className={styles["dropdown__item-icon"]}>
                <Icon name="gear" />
              </div>

              <p className={styles["dropdown__item-text"]}>Настройки</p>
            </button>
          )}

          <button
            className={cn(
              styles.dropdown__item,
              styles.dropdown__item_dangerous
            )}
            onClick={() => onDelete?.(channel)}
          >
            <div className={styles['dropdown__item-icon']}>
              <Icon name="trash-bin" />
            </div>

            <p className={styles['dropdown__item-text']}>Удалить</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default ConnectedChannel;