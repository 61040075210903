import { useEffect, useMemo } from "react";
import classNames from "classnames";
import Icon from "../Icon";
import { useScreenWidth } from '../../hooks/useScreenWidth';
import s from "./Pagination.module.css";

type Props = {
    pageCount: number;
    currentPage?: number;
    paginationClassName?: string;
    paginationItemClassName?: string;
    onChangePage: (pageNumber: number) => void;
};

const getMobilePagesToShow = (currentPage: number, pageNumbers: number[]) => {
    if (currentPage === pageNumbers.length) {
        return pageNumbers.slice(-2);
    }
    const startIndex = currentPage - 1;
    const endIndex = Math.min(currentPage + 1, pageNumbers.length);
    return pageNumbers.slice(startIndex, endIndex);
};

const getDesktopPagesToShow = (currentPage: number, pageNumbers: number[], maxToShow: number) => {
    const showBefore = Math.floor(maxToShow / 2);
    const showAfter = maxToShow - showBefore - 1;

    let startIndex = Math.max(currentPage - 1 - showBefore, 0);
    let endIndex = Math.min(currentPage + showAfter, pageNumbers.length);

    if (startIndex === 0) {
        endIndex = Math.min(maxToShow, pageNumbers.length);
    } else if (endIndex === pageNumbers.length) {
        startIndex = Math.max(pageNumbers.length - maxToShow, 0);
    }

    return pageNumbers.slice(startIndex, endIndex);
};

const getLastOf = <T,>(list: T[]): T => list[list.length - 1];

const calculateNextPage = (
    goTo: 'prev' | 'next' | number,
    currentPage: number,
    pageNumbers: number[]
): number => {
    if (typeof goTo === 'number') {
        return goTo;
    }

    if (goTo === 'prev') {
        return Math.max(currentPage - 1, pageNumbers[0]);
    }

    return Math.min(currentPage + 1, getLastOf(pageNumbers));
};

export function Pagination({
    pageCount,
    currentPage = 1,
    onChangePage,
    paginationClassName,
    paginationItemClassName
}: Props) {
    const { isMobile } = useScreenWidth();

    const pageNumbers = useMemo(() =>
        new Array(pageCount).fill(1).map((_, index) => index + 1),
        [pageCount]
    );

    const pagesToShow = useMemo(() =>
        isMobile
            ? getMobilePagesToShow(currentPage, pageNumbers)
            : getDesktopPagesToShow(currentPage, pageNumbers, 5),
        [currentPage, pageNumbers, isMobile]
    );

    const handleClick = (goTo: 'prev' | 'next' | number) => {
        const nextPage = calculateNextPage(goTo, currentPage, pageNumbers);
        onChangePage(nextPage);
    };

    const isPrevButton = currentPage > 1;
    const isNextButton = currentPage < pageNumbers.length;

    useEffect(() => {
        if (currentPage > pageNumbers.length) {
            onChangePage(pageNumbers.length);
        }
    }, [pageCount, currentPage, pageNumbers, onChangePage]);

    if (pagesToShow.length <= 1) return null;

    return (
        <ul className={classNames(paginationClassName, s['pagination'])}>
            <li
                className={classNames(
                    paginationItemClassName,
                    s['pagination__item'],
                    { [s['pagination__item_no-pointer']]: !isPrevButton }
                )}
                onClick={() => isPrevButton && handleClick('prev')}
            >
                {isPrevButton && (
                    <span className={s['pagination__button']}>
                        <Icon name="arrow" />
                    </span>
                )}
            </li>
            {pagesToShow.map((pageNumber) => (
                <li
                    key={pageNumber}
                    className={classNames(
                        paginationItemClassName,
                        s['pagination__item'],
                        { [s['pagination__item_active']]: currentPage === pageNumber }
                    )}
                    onClick={() => handleClick(pageNumber)}
                >
                    {pageNumber}
                </li>
            ))}
            <li
                className={classNames(
                    paginationItemClassName,
                    s['pagination__item'],
                    { [s['pagination__item_no-pointer']]: !isNextButton }
                )}
                onClick={() => isNextButton && handleClick('next')}
            >
                {isNextButton && (
                    <span className={classNames(s['pagination__button'], s['pagination__button_rotate-180'])}>
                        <Icon name="arrow" />
                    </span>
                )}
            </li>
        </ul>
    );
}