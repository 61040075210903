import { FC } from 'react';

// * Icons
import hint from "./assets/hint.svg";

// * Components
import Tooltip from '../Tooltip/Tooltip';

// * Props
import { TooltipProps } from '../Tooltip/Tooltip.interface';

// * Styles
import styles from "./TooltipButton.module.css";

const TooltipButton: FC<TooltipProps> = ({ text, position, enableHtml }) => {
  return (
    <div className={styles["tooltip-button"]}>
      <Tooltip text={text} position={position} enableHtml={enableHtml}>
        <img className={styles["tooltip-button__hint"]} src={hint} alt="Hint" />
      </Tooltip>
    </div>
  );
};

export default TooltipButton;