import { FC, useRef, useState } from "react";

import { useGetUserQuery } from "../../../features/api/apiSlice";

// * Components
import Icon from "../../Icon";
import Menu from "./Menu";
import { CSSTransition } from "react-transition-group";

// * Hooks
import { useClickOutside } from "../../../hooks/useClickOutside";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./HeaderUserMenu.module.css";

const HeaderUserMenu: FC = () => {
  const { data: user } = useGetUserQuery();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    setIsMenuOpen(false);
  });

  return (
    <div
      ref={containerRef}
      className={styles.user}
      onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
    >
      <div
        className={cn(styles.user__avatar, {
          [styles.user__avatar_empty]: !user?.avatar,
        })}
      >
        {user?.avatar ? (
          <img className={styles["user__avatar-image"]} src={user?.avatar} />
        ): (
          <Icon name="user" />
        )}
      </div>

      <div className={styles.user__info}>
        <p className={cn(styles.user__name, {[styles.user__name_active]: isMenuOpen})}>
          {user?.user_name}
        </p>

        <p className={styles.user__tariff}>
          Тариф: {user?.tariff.name}
        </p>
      </div>

      <CSSTransition
        nodeRef={menuRef}
        in={isMenuOpen}
        timeout={200}
        unmountOnExit
        classNames="fade-in"
      >
        <div ref={menuRef} className={styles.user__menu}>
          <Menu />
        </div>
      </CSSTransition>
    </div>
  );
};

export default HeaderUserMenu;
