import { FC } from 'react';
import styles from './ButtonToggleOnOff.module.css';
import { useAppSelector } from '../../app/hooks';

export interface ButtonToggleOnOffProps {
    onToggleOn: () => void;
    onToggleOff: () => void;
    className?: string;
    disabled?: boolean;
}

export const ButtonToggleOnOff: FC<ButtonToggleOnOffProps> = ({
    onToggleOn,
    onToggleOff,
    className,
    disabled = false
}) => {
    const { theme } = useAppSelector((state) => state.options);

    return (
        <div className={`${styles.container} ${className || ''}`}>
            <div className={styles["container-button"]}>
                <button
                    onClick={onToggleOn}
                    disabled={disabled}
                    className={theme === "dark" ? styles["button-on-dark"] : styles["button-on-light"]}
                >
                    ON
                </button>
                <div className={styles["container-button"]}>
                    <span className={theme === "dark" ? styles["distance-between-buttons-dark"] : styles["distance-between-buttons-light"]}>
                        |
                    </span>
                    <button
                        onClick={onToggleOff}
                        disabled={disabled}
                        className={theme === "dark" ? styles["button-off-dark"] : styles["button-off-light"]}
                    >
                        OFF
                    </button>
                </div>
            </div>
        </div>
    );
};