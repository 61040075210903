import React from "react";
import { PopupStructureProps } from './PopupStructure.interface';
import styles from './PopupStructure.module.css';
import cn from "classnames";

const PopupStructure: React.FC<PopupStructureProps> = ({
  headerComponent,
  contentComponent,
  footerComponent,
  className,
}) => {
  return (
    <div className={styles.structure}>
      {headerComponent && (
        <div className={styles.structure__header}>
          {headerComponent}
        </div>
      )}

      <div className={styles.structure__wrapper}>
        {contentComponent && (
          <div className={cn(styles.structure__content, className)}>
            {contentComponent}
          </div>
        )}
      </div>

      {footerComponent && (
        <div className={styles.structure__footer}>
          {footerComponent}
        </div>
      )}
    </div>
  );
};

export default PopupStructure;