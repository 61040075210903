import Icon from "../Icon";
import cn from "classnames";
import s from "./Chip.module.css";
import { useState, ReactNode } from "react";

interface IChip {
  text?: string;
  isTooltip?: boolean;
  tooltipContent?: ReactNode;
  onDelete?: () => void;
}

function Chip({ text, isTooltip = false, tooltipContent, onDelete }: IChip) {
  const [showTooltip, setShowTooltip] = useState(false);

  const deleteChipHandler = () => {
    if (isTooltip) {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 10000);
    } else {
      onDelete?.();
    }
  };

  return (
    <div className={s["chip"]}>
      <span className={s["chip__text"]}>{text}</span>
      <button
        onClick={deleteChipHandler}
        type="button"
        className={s["chip__button"]}
      >
        <Icon name="close" />
      </button>
      {isTooltip && tooltipContent && (
        <div
          className={cn(s['chip__tooltip'], {
            [s['chip__tooltip_visible']]: showTooltip
          })}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
}

export default Chip;
