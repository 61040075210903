import React, { FC } from 'react';
import { Popup } from '../Popup';
import PopupStructure from '../PopupStructure';
import PopupTitleDangerous from '../PopupTitleDangerous';
import { ButtonGroup } from '../ButtonGroup';
import { Button } from '../Button';
import { DeleteChannelPopupProps } from './DeleteChannelPopup.interface';

const DeleteChannelPopup: FC<DeleteChannelPopupProps> = ({
  channel,
  onClose,
  onDelete,
  isShow
}) => {
  const handleDelete = () => {
    if (channel) {
      onDelete(channel.id);
    }
    onClose();
  };

  return (
    <Popup isShow={isShow} onClose={onClose}>
      <PopupStructure
        headerComponent={
          <PopupTitleDangerous>
            Предупреждение
          </PopupTitleDangerous>
        }
        contentComponent={
          <div>Вы уверены, что хотите удалить канал "{channel?.name}"?</div>
        }
        footerComponent={
          <ButtonGroup>
            <Button kind="secondary" onClick={handleDelete}>Удалить</Button>
            <Button kind="primary" onClick={onClose}>Нет</Button>
          </ButtonGroup>
        }
      />
    </Popup>
  );
};

export default DeleteChannelPopup;