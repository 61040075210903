import { FC, useState } from 'react';

// * Props
import { AsideWithTabsProps, BaseTabItem, ChannelsTabItem } from './AsideWithTabs.interface';

// * Helpers
import cn from "classnames";

// * Components
import { ButtonGroup } from '../ButtonGroup';
import { Button } from '../Button';
import PopupChannelPagination from '../Stream/Channels/PopupChannelPagination/PopupChannelPagination';

// * Styles
import styles from "./AsideWithTabs.module.css";
import Icon from '../Icon';

const isChannelsTab = (tab: BaseTabItem): tab is ChannelsTabItem => {
  return tab.title === 'Каналы';
};

const hasActiveChannels = (tab: ChannelsTabItem): boolean => {
  return tab.channels?.some(channel => channel.enabled);
};

const AsideWithTabs: FC<AsideWithTabsProps> = ({ items }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const activeTab = items[activeTabIndex];

  const handleTogglePopup = () => {
    setPopupOpen(prev => !prev);
  };

  return (
    <div className={cn(styles.aside)}>
      <div className={styles.aside__header}>
        <div className={styles.aside__tabs}>
          {items.map((item, idx) =>
            <div
              className={cn(styles.aside__tab, {[styles["aside__tab_active"]]: idx === activeTabIndex})}
              key={item.title}
              onClick={() => setActiveTabIndex(idx)}
            >
              <p className={styles["aside__tab-text"]}>
                {item.title}
              </p>
              {item.tabIcon && (
                <div className={styles["aside__tab-icon"]}>
                  <Icon name={item.tabIcon} />
                </div>
              )}
            </div>
          )}
        </div>

        {isChannelsTab(activeTab) && (
          <ButtonGroup>
            <Button
              className={styles.aside__management}
              kind="primary"
              onClick={handleTogglePopup}
            >
              Управление каналами
            </Button>
          </ButtonGroup>
        )}
      </div>

      <div className={styles.aside__content}>
        {isChannelsTab(activeTab) && !hasActiveChannels(activeTab) ? (
          <div className={styles.aside__empty}>
            <p className={styles.empty_text}>
              Здесь будут ваши активные каналы
            </p>
          </div>
        ) : (
          activeTab.component
        )}
      </div>

      {isChannelsTab(activeTab) && (
        <PopupChannelPagination
          isOpen={isPopupOpen}
          onClose={handleTogglePopup}
          onAddNewChannel={activeTab.onAddNewChannel}
          onDelete={activeTab.onDelete}
          onEdit={activeTab.onEdit}
          onToggleEnabled={activeTab.onToggleEnabled}
          channelStatuses={activeTab.channelStatuses}
        />
      )}
    </div>
  );
};

export default AsideWithTabs;