import { forwardRef } from "react";

//* Props
import { TextAreaFieldProps } from "./TextAreaField.interface";

// * Helpers
import cn from "classnames";

//* Styles
import styles from "./TextAreaField.module.css";

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  ({
    label,
    error,
    className,
    isError,
    maxLength,
    usedChars = 0,
    ...restProps
  }, ref) => {
    return (
      <div className={cn(styles["container"], {[styles["container_error"]]: isError})}>
        <label className={styles.field}>
          {label && <div className={styles.field__label}>{label}</div>}

          <textarea
            {...restProps}
            ref={ref}
            className={cn(className, styles["field__textarea"])}
          />

          {error && <div className={styles.field__error}>{error}</div>}

          {maxLength && (
            <div className={styles.field__counter}>
              {usedChars}/{maxLength}
            </div>
          )}
        </label>
      </div>
    );
  }
);

export default TextAreaField;
