import { ComponentProps } from "react";
import classNames from "classnames";
import s from './Button.module.css';

type Kind = {
    kind: 'primary' | 'secondary';
};

type ButtonProps = ComponentProps<'button'> & Kind;
type LinkButtonProps = ComponentProps<'a'> & Kind;

function getButtonBaseClassName(className: string | undefined, kind: Kind['kind']) {
    return classNames(className, s['button'], s[`button_${kind}`]);
}

export function Button({ kind, className, ...restProps }: ButtonProps) {
    let buttonClassName = getButtonBaseClassName(className, kind);
    return <button {...restProps} className={buttonClassName} />;
}

export function LinkButton({ kind, className, ...restProps }: LinkButtonProps) {
    const linkClassName = getButtonBaseClassName(className, kind);
    return <a {...restProps} className={linkClassName} />;
}
