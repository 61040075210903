import React, { FC } from 'react';
import { Popup } from '../Popup';
import PopupStructure from '../PopupStructure';
import PopupTitleDangerous from '../PopupTitleDangerous';
import { ButtonGroup } from '../ButtonGroup';
import { Button } from '../Button';
import { MultipleDeleteChannelPopupProps } from './MultipleDeleteChannelPopup.interface';
import { useDeleteChannelsMutation } from '../../features/api/apiSlice';

const MultipleDeleteChannelPopup: FC<MultipleDeleteChannelPopupProps> = ({
    selectedIds,
    onClose,
    onSuccess,
    isShow
}) => {
    const [deleteChannels] = useDeleteChannelsMutation();

    const handleDelete = async () => {
        if (selectedIds.length) {
            try {
                await deleteChannels(selectedIds).unwrap();
                onSuccess();
            } catch (error) {
                console.error('Failed to delete channels:', error);
                onClose();
            }
        } else {
            onClose();
        }
    };

    return (
        <Popup isShow={isShow} onClose={onClose}>
            <PopupStructure
                headerComponent={
                    <PopupTitleDangerous>
                        Предупреждение
                    </PopupTitleDangerous>
                }
                contentComponent={
                    <div>
                        Вы уверены, что хотите удалить выбранные каналы ({selectedIds.length})?
                    </div>
                }
                footerComponent={
                    <ButtonGroup>
                        <Button kind="secondary" onClick={handleDelete}>Удалить</Button>
                        <Button kind="primary" onClick={onClose}>Нет</Button>
                    </ButtonGroup>
                }
            />
        </Popup>
    );
};

export default MultipleDeleteChannelPopup;